export type Flash = {
  notice?: string
  alert?: string
}

export type User = {
  id: string
  email: string
  admin: boolean
}

export type Rails = {
  flash: Flash
  user?: User
  guestToken?: string
}

export const flashMessages = () => {
  return window.APP.rails.flash
}

export const currentUser = () => {
  return window.APP.rails.user
}

export const guestToken = () => {
  return window.APP.rails.guestToken
}

export const isGuestAccess = () => {
  return !currentUser() && !!guestToken()
}
